<template>
    <ErrorRenderer v-if="capturedError" />
    <component
        v-else
        :is="tag"
        :contenteditable="isEditable"
        :placeholder="placeholder"
        :class="['o365-contenteditable', {'text-muted': showPlaceholder}]"
        @input="update"
        @blur="update"
        @paste="onPaste"
        @keypress="onKeypress"
        @keydown="onKeydown"
        ref="element">
    </component>
</template>

<script setup>
import { defineProps, ref, computed, onMounted, watch } from 'vue';
import useErrorCapture from 'o365.vue.composables.ErrorCapture.ts';

const props = defineProps({
    tag: {
        type: String,
        default: 'div'
    },
    isEditable: {
        type: Boolean,
        default: true
    },
    modelValue: null,
    renderHtml: Boolean,
    noNL: Boolean,
    placeholder: String
});

const emit = defineEmits(['returned', 'update:modelValue', 'test']);

const element = ref(null);

const showPlaceholder = computed(() => {
     return props.placeholder && !props.modelValue
});

function currentContent(){
    return props.renderHtml
        ? element.value.innerHTML
        : element.value.innerText;
}

function updateContent(newContent){
    if(props.renderHtml) {
        element.value.innerHTML = newContent;
    }
    else {
        element.value.innerText = newContent;
    }
}

function update(e) {
    try {
        const value = currentContent();
        emit('update:modelValue', value);
    } catch (_ex) {
        // Sometimes during unmount this gets triggered and the cuttentContent function fails
    }
}

function onPaste(e) {
    e.preventDefault();
    let text = (e.originalEvent || e).clipboardData.getData('text/plain');
    //Some cleanup needs to be made here
    //as this is dprecated, we need to find clever way of inserting conent into selection
    window.document.execCommand('insertText', false, text);
}

function onKeypress(e) {
    if(e.key == 'Enter' && props.noNL) {
        e.preventDefault();
        emit('returned', currentContent());
    }
}

function onKeydown(e) {
    if (e.key === 'Enter' && e.shiftKey) {
        e.stopPropagation();
    }
}

onMounted(() =>{
    updateContent(props.modelValue ?? '');
});

watch(() => props.modelValue, (newVal, oldVal) => {
    if(newVal != currentContent()){
        updateContent(newVal ?? '');
    }
});

watch(() => props.noHtml, (newVal, oldVal)  => {
    updateContent(props.modelValue ?? '');
});

watch(() => props.tag, (newVal, oldVal)  => {
    updateContent(props.modelValue ?? '');
}, { flush: 'post' });


const [capturedError, ErrorRenderer] = useErrorCapture({
    consoleMessagee: `Error encountered when trying to render OContentEditable`,
    errorRenderFunctionOptions: {
        uiTitleMessage: 'ContentEditable Render Error'
    }
});
</script>

<style scoped>
.o365-contenteditable:empty:before{
    content: attr(placeholder);
}
</style>